<template>
  <PageContainer>
    <PageTitle class="w-full lg:w-form-auth-default">
      {{ trans('FORM_TITLE:LogIn') }}
    </PageTitle>
    <t-card variant="auth">
      <div>
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <form
              ref="registerForm"
              @submit.prevent="handleFormSubmit"
          >
            <div class="flex flex-col gap-y-3">
              <FieldWrapper>
                <TextField
                    field="email"
                    rules="email|required"
                    name="email"
                    autocomplete
                    :placeholder="trans('LABEL:Email')"
                    v-model="email"
                />
              </FieldWrapper>
              <FieldWrapper>
                <TextField
                    isPassword
                    field="password"
                    rules="required"
                    :placeholder="trans('LABEL:Password')"
                    v-model="password"
                />
              </FieldWrapper>
            </div>
            <div class="mt-3">
              <CheckboxField
                  v-model="rememberMe"
                  :label="trans('BUTTON:RememberMe')"
              />
            </div>
            <div class="flex items-center justify-end mt-3">
              <Button
                variant="link"
                class="mr-3 text-s "
                :to="{name: 'auth-forgot-password'}"
                :text="trans('BUTTON:ForgotYourPassword')"
              />
              <Button
                variant="buttonRed"
                type="submit"
                :disabled="false"
                :loading="status.loggingIn"
                :text="trans('BUTTON:LogMeIn')"
              />
            </div>
            <div class="mt-3 text-s ">
              {{ trans('TEXT:LoginNoAccountYet') }}
              <Button
                :to="{name: 'auth-register'}"
                variant="underline"
                :text="trans('BUTTON:SignIn')"
              />
            </div>
          </form>
        </ValidationObserver>
      </div>
    </t-card>
  </PageContainer>
</template>

<script>
import themeConfig from '@/themeConfig.js'
import { mapState, mapActions, mapGetters } from 'vuex'
import Button from "@/utils/crud/components/Button";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import TextField from "@/utils/crud/components/field-types/Text";
import CheckboxField from "@/utils/crud/components/field-types/Checkbox";
import PageContainer from "@/layouts/components/pages/PageContainer.vue";
import PageTitle from "@/layouts/components/pages/PageTitle.vue";

export default {
  data () {
    return {
      email: '',
      password: '',
      rememberMe: false,
    }
  },
  components: {
    PageTitle,
    PageContainer,
    CheckboxField,
    Button, TextField, FieldWrapper
  },
  computed: {
    ...mapState('account', ['status']),
    ...mapGetters('account', ['errors'])
  },
  methods: {
    ...mapActions('account', ['login', 'logout']),
    ...mapActions('chat', ['getNotReadCount']),
    handleFormSubmit (e) {
      e.preventDefault();

      const { email, password, rememberMe } = this;
      if (email && password) {
        if(this.$refs.form.validate()) {

          this.login({ email, password, rememberMe })
        }
      }
    }
  },
  setup() {

    return {
      icons: {
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" >
</style>
